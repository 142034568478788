// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/notifications/config.ts"
);
import.meta.hot.lastModified = "1715823653471.4475";
}
// REMIX HMR END

import { HistoryEntryType } from '~admin/generated/graphql'
import { capitalizeFirstLetter } from '~common/utils/format/capitalizeFirstLetter'

export const config = {
  path: '/dashboard/notifications',
  apiPath: '/api/notification',
}

const processKey = (type: HistoryEntryType) => {
  const tmp = type.toString().toLowerCase().split('_')
  tmp[0] = capitalizeFirstLetter(tmp[0])
  return tmp.join(' ')
}

export const notificationTitle = (type: HistoryEntryType) => {
  switch (type) {
    default:
      return processKey(type)
  }
}
